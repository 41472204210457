<template>
  <v-theme-provider dark>
    <base-section
      id="zitat"
      class="primary"
      space="36"
    >
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-title
          space="0"
          title="Du bist einzigartig, mit deinem ganzen Selbst. "
        />
      </v-row>
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-subtitle
          class="subtitle"
          space="0"
          title="Monica Bachmann"
        />
      </v-row>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionZitat',
  }
</script>

<style scoped>
.subtitle {
  color: white !important;
}
</style>
